import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import logoImage from '../img/logo.png';

const Header = ({ handleShow, handleLoginShow }) => {
  return (
    <div>
      <header className="p-3 header-section">
        <div className="container d-block d-md-none">
          <div className="row">
            <div className='col'>
              <img src={logoImage} className='img' alt="Logo" style={{ height: '45px', float:'left', marginLeft:'20px' }} />
              <a href="https://api.whatsapp.com/send?phone=5521972424521&amp;text=Ola!" style={{ float: 'right' }}
                target="_blank" className="btn btn-link" rel="noopener noreferrer">
                <img className='img' style={{ height: '40px',  width: '40px' }} src="https://icones.pro/wp-content/uploads/2021/02/icone-du-logo-whatsapp-vert.png" alt="WhatsApp" />
              </a>
            </div>
          </div>
        </div>
        <div className="container d-none d-md-block">
          <div className="d-flex justify-content-between align-items-center">
            <div className='d-flex'>
              <img src={logoImage} className='img' alt="Logo" style={{ height: '45px', }} />
            </div>
            <div className="d-flex align-items-center">
              <nav className="me-3">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <a href="#problem" className="btn btn-gradient">Quem somos</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#calculator" className="btn btn-gradient">Calculadora</a>
                  </li>
                  <li className="list-inline-item">
                    <button className="btn btn-dark btn-md px-4" onClick={handleShow}>Quero uma proposta</button>
                  </li>
                  <li className="list-inline-item">
                    {/*<button className="btn btn-dark btn-md px-4" onClick={handleLoginShow}>Acessar</button>*/}
                    <a className="btn btn-dark btn-md px-4" href='https://app.vizineo.com'>Acessar</a>
                  </li>
                </ul>
              </nav>
              <div>
                <a href="https://api.whatsapp.com/send?phone=5521972424521&amp;text=Ola!"
                  target="_blank" className="btn btn-link" rel="noopener noreferrer">
                  <img className='img' style={{ height: '40px' }} src="https://icones.pro/wp-content/uploads/2021/02/icone-du-logo-whatsapp-vert.png" alt="WhatsApp" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
