import React, { useState } from 'react';
import CircularProgressBar from './CircularProgressBar';
import calculatorImage from '../img/calculator.png';

const CDI = 0.0083;
const CO = 10;

const CalculatorSection = () => {
  const [fundoReserva, setFundoReserva] = useState('');
  const [taxaCondominial, setTaxaCondominial] = useState('');
  const [unidades, setUnidades] = useState('');
  const [perda, setPerda] = useState(5388);
  const [progress, setProgress] = useState(2.25);
  const [progressVisual, setProgressVisual] = useState(82.04);

  const handleCalculate = (e) => {
    e.preventDefault();
    const FD = parseFloat(fundoReserva) || 30000;
    const Tx = parseFloat(taxaCondominial) || 1000;
    const U = parseInt(unidades) || 20;

    const perdaFinanceira = FD * CDI * 12 + U * CO * 12;
    const perdaPercentual = (perdaFinanceira / (Tx * U * 12)) * 100;
    const perdaVisual = ((FD - perdaFinanceira) / FD) * 100;

    setPerda(perdaFinanceira);
    setProgress(perdaPercentual);
    setProgressVisual(perdaVisual);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  return (
    <section id="calculator" className="calculator-section text-start py-5 slide">
      <div className="container p-5">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="text-start">
              <h3 className="display-5 fw-bold">Calculadora de receita desperdiçada</h3>
            </div>
            <p className='h5'>Com a Vizineo os rendimentos do dinheiro são revertidos em benefício do condomínio e não da Administradora. </p>
            <p className='h5'>Descubra aqui o saldo excedente que seu condomínio pode ter:</p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-5 offset-md-1 order-2 order-md-2">
            <div className="card p-3">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <img src={calculatorImage} className='img-fluid' alt="Hero" />
                </div>
                <div className="col-md-8 text-start">
                  <h4>Receita extra potencial <br /> <span style={{ color: '#64DD16' }}>{formatCurrency(perda)}</span><br /> ao ano com a Vizineo
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 offset-md-1 order-1 order-md-1 p-md-0">
            <form className="row g-3" onSubmit={handleCalculate}>
              <div className="col-md-4">
                <label htmlFor="fundoReserva" className="form-label">Fundo de reserva atual (R$)</label>
                <input
                  type="number"
                  className="form-control"
                  id="fundoReserva"
                  placeholder="R$ 30.000,00"
                  value={fundoReserva}
                  onChange={(e) => setFundoReserva(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="taxaCondominial" className="form-label">Cota condominial mensal (R$)</label>
                <input
                  type="number"
                  className="form-control"
                  id="taxaCondominial"
                  placeholder="R$ 1.000,00"
                  value={taxaCondominial}
                  onChange={(e) => setTaxaCondominial(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="unidades" className="form-label">Número de apartamentos</label>
                <input
                  type="number"
                  className="form-control"
                  id="unidades"
                  placeholder="20"
                  value={unidades}
                  onChange={(e) => setUnidades(e.target.value)}
                  required
                />
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-lg btn-primary px-5 mb-5">Calcular</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CalculatorSection;
