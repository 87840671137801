import React from 'react';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';  // Importing react-icons for social media icons
import logoImage from '../img/logo.png';  // Importing the logo image

const Footer = ({ handleShow }) => {
  return (
    <footer className="footer text-center py-4" style={{ backgroundColor: '#6a11cb', color: 'white', minHeight: '120px' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3">
            <img src={logoImage} alt="Logo" className="img-fluid mb-4 mt-3" style={{ maxHeight: '40px'}} />
            <div>
            <a href="https://www.linkedin.com/company/104031558" target="_blank" rel="noopener noreferrer" className="text-white me-4">
              <FaLinkedin size={32} />
            </a>
            <a href="https://www.instagram.com/vizineobr" target="_blank" rel="noopener noreferrer" className="text-white">
              <FaInstagram size={32} />
            </a>
            </div>
          </div>
          <div className="col-md-4 mb-3 text-start"> <br/> <br/>
          </div>
          <div className="col-md-4 mb-3 text-center mt-2">
            <p>Ataulfo de Paiva, 1120 - Leblon - RJ <br/>
            <strong>Tel:</strong> +55 (21) 972424521</p>
            <button className="btn btn-light mt-1 btn-md px-4 mb-3 " onClick={handleShow}>Quero uma proposta</button>


          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
